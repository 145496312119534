import React from 'react';
import { NavLink } from 'react-router-dom';
import Search from 'src/components/parts/SearchBox';
import Img from 'react-image';
import icon_menu from 'public/static/img/icon_menu.png';
import icon_search from 'public/static/img/icon_search.png';
import x_icon from 'public/static/img/x_icon.png';
import { connect } from "react-redux";

//	This maps the data store to local variables.
const mapStateToProps = state  => {
	return {};
};

class MobileMenu extends React.Component {

	constructor(props) {

		super(props);
		this.state = {isOpen: false }
		this.handleMenuButton = this.handleMenuButton.bind(this);

	}

	//	Update menu state
	handleMenuButton(event) {
		this.setState({ isOpen: !this.state.isOpen });
	}

	//	click free account.
	loadRegister = (event) => {
		
		this.setState({ isOpen: false });
		this.props.dispatch({type: 'REGISTER'});

	};

	renderMobileMenuOpened = () => {

		return (
			<div className="menu_container">
				<div className="overlay" onClick={this.handleMenuButton}></div>
				<div id="menu">
					<div className="menu_top">
						<div className="free_account_btn" onClick={this.loadRegister}><a>FREE ACCOUNT</a></div>
						<div className="close_menu_btn" onClick={this.handleMenuButton}><Img src={x_icon} className="close_menu_icon" alt="Close Menu" /></div>
					</div>
					<div className="menu_middle">
						<h1>The most dominant women in the world on cam! Personal Femdom sessions with cruel dominatrix 24/7.</h1>
					</div>
					<Search />
					<ul className="menu_list">
						<li className="category_heading">DOMINATRIX WEBCAMS</li>
						<li><NavLink to="/category/teen/" activeClassName="active_menulink" onClick={this.handleMenuButton}>TEEN 18+</NavLink></li>
						<li><NavLink to="/category/milf/" activeClassName="active_menulink" onClick={this.handleMenuButton}>MILF</NavLink></li>
						<li><NavLink to="/category/petite/" activeClassName="active_menulink" onClick={this.handleMenuButton}>PETITE DOMME</NavLink></li>
						<li><NavLink to="/category/muscle/" activeClassName="active_menulink" onClick={this.handleMenuButton}>MUSCLE MISTRESS</NavLink></li>
						<li><NavLink to="/category/bbw/" activeClassName="active_menulink" onClick={this.handleMenuButton}>BBW DOMINA</NavLink></li>
						<li><NavLink to="/category/busty/" activeClassName="active_menulink" onClick={this.handleMenuButton}>BUSTY DOMINATRIX</NavLink></li>
						<li className="category_heading">FEMDOM SHOWS</li>
						<li><NavLink to="/category/recommended/" activeClassName="active_menulink" onClick={this.handleMenuButton}>TOP PICKS</NavLink></li>
						<li><NavLink to="/category/hd/" activeClassName="active_menulink" onClick={this.handleMenuButton}>HD DUNGEONS</NavLink></li>
						<li><NavLink to="/category/new/" activeClassName="active_menulink" onClick={this.handleMenuButton}>NEW SHOWS</NavLink></li>
					</ul>
				</div>
			</div>
		)
	}


	renderMobileMenuClosed = () => {

		return(
			<div className="menu_openers">
				<div className="open_search" onClick={this.handleMenuButton}>
					<Img src={icon_search} className="header_icon" alt="Search Icon" />
					<h1>SEARCH</h1>
				</div>
				<div className="open_menu" onClick={this.handleMenuButton}>
					<Img src={icon_menu} className="header_icon" alt="Menu Icon" />
					<h1>MENU</h1>
				</div>
			</div>
		)

	}

	render() {

		return (
			<div>
				{this.state.isOpen ?  this.renderMobileMenuOpened() : this.renderMobileMenuClosed()}
			</div>
		);
	}
}

export default connect(mapStateToProps)(MobileMenu);