import React from 'react';
import {Helmet} from 'react-helmet';
import 'src/App.css';

const Blank = () => {

    return (
		<div>
			<h1>Loading...</h1>
			<Helmet>
				<title>Loading</title>
				<meta name="robots" content="noindex" />
				<meta name="googlebot" content="noindex" />
			</Helmet>
		</div>
    );
}

export default Blank;