import { createStore } from "redux";



const initialState = {
	meta_title: "",
	meta_description: "",
	top_text: "",
	text_content:"",
	footer_text:"",
	currentUser: [],
	users:[],
	usersVisible:[],
	usersTotal:0,
	showItems: (window.innerWidth > 768) ? 120 : 12,
	showMoreItems: (window.innerWidth > 768) ? 60 : 12,
	loadingMessage: false,
	userOrder: typeof(localStorage.getItem('userOrder')) === 'undefined' ? JSON.parse(localStorage.getItem('userOrder') ) : "",
	usersCompleteLoad: false,
	registerOpen: false,
}

for(var i=0;i<59;i++){
	initialState.usersVisible[i] = {id: i, username: "", age: "", picture: ""};
}

const rootReducer = (state = initialState, action) => {
	
	switch( action.type ) {

		case 'GET_TEXT':
			return {
				...state,
				meta_title: action.data.meta_title,
				meta_description: action.data.meta_description,
				top_text: action.data.top_text,
				text_content: action.data.text_content,
				footer_text: action.data.footer_text,
				models_online_text: action.data.models_online_text
			}
		
		case 'GET_USERS':
			
			//state.total_online

			// if the number of items is less than what we put in as holders, we will need to clear it so they aren't left there.
			if( action.data.total < state.showItems ) {
				state.usersVisible={};
			} else {
				state.loadingMessage = true;
			}

			return {
				...state,
				users: action.data.users,
				usersVisible: action.data.users.slice( 0, state.showItems ),
				usersTotal: action.data.total
			}
		
		case 'MORE_USERS':
			
			state.loadingMessage = true;

			return {
				...state,
				showItems: state.showItems + state.showMoreItems,
				usersVisible: state.users.slice( 0, state.showItems )
			}
		
		case 'MORE_USERS_DONE':
			return {
				...state,
				loadingMessage: false
			}
		
		// Order users by Name, Popularity...
		case 'ORDER_USERS':

			if(action.order === 'STATE' ) {
				action.order = state.userOrder;
			}

			if(action.order === 'ZTOA'){
				
				// Sort users by name case insensitive Z-A
				state.users.sort( function(a,b) {

					if (a.username.toLowerCase() > b.username.toLowerCase())	return -1;
					if (b.username.toLowerCase() > a.username.toLowerCase())	return 1;
					return 0;

				})

			} else if(action.order === 'ATOZ') {

				state.users.sort( function(a,b) {

					if (a.username.toLowerCase() > b.username.toLowerCase())	return 1;
					if (b.username.toLowerCase() > a.username.toLowerCase())	return -1;
					return 0;

				})

			} else if(action.order === 'POPULAR') {
				
				state.users.sort( function(a,b) {

					if (a.rating < b.rating)	return 1;
					if (b.rating < a.rating)	return -1;
					return 0;

				})

			} else if(action.order === 'USERS') {
				
				state.users.sort( function(a,b) {

					if (a.chatters < b.chatters)	return 1;
					if (b.chatters < a.chatters)	return -1;
					return 0;

				})

			} else if(action.order === 'OLDEST') {
				
				state.users.sort( function(a,b) {

					//	A lot of models put 99 as fake age.
					if( a.age > '90' ) return 1;
					if( b.age > '90' ) return -1;

					if (a.age < b.age)	return 1;
					if (b.age < a.age)	return -1;
					return 0;

				})

			} else if(action.order === 'YOUNGEST') {
				
				state.users.sort( function(a,b) {

					// Move age 0 to bottom -1, 1  | 1,1 | 1,-1 | -1 -1
					if( a.age == '0' ) return 1;
					if( b.age == '0' ) return 1;

					if (a.age > b.age)	return 1;
					if (b.age > a.age)	return -1;
					return 0;

				})

			}

			//	Set the current order state, also set the local storage for the page load.
			//console.log( action.order );
			state.userOrder = action.order;
			
			localStorage.setItem('userOrder', JSON.stringify(action.order));
			
			//	Set the users visible state
			return {
				...state,
				usersVisible: state.users.slice( 0, state.showItems )
			}



		case 'RESET_SHOW_USERS':
			return {
				...state,
				usersCompleteLoad: false,
				showItems: initialState.showItems
			}


		case 'GET_CHATROOM':
			return {
				...state,
				currentUser: action.data
			}

		case 'GET_ALL_USERS_STARTED':
			return {
				...state,
				usersCompleteLoad: true
			}

		case 'REGISTER':
			return{
				...state,
				registerOpen: !state.registerOpen 
			}
			

		// Sort Users..
		default:
			return state;

	}

}


//	Create the store
export const configureStore = () => {
	
	const store = createStore(
	  rootReducer, // root reducer
	  initialState, // our initialState
	);
	
	return store;
}