import React, { Component } from 'react';
import SideMenuDesktop from 'src/components/parts/SideMenuDesktop';
import UserContainer from 'src/components/parts/UserContainer';
import {Helmet} from 'react-helmet';
import { connect } from "react-redux";
import { setText, setUsers } from "src/cms.js";
import TextContent from 'src/components/parts/TextContent';
import Header from 'src/components/parts/Header';
import Footer from 'src/components/parts/Footer';

//	This maps the data store to local variables.
const mapStateToProps = state  => {
	return {
	  meta_title: state.meta_title,
	  meta_description: state.meta_description,
	};
};

class Tag extends Component {

	constructor(props) {

		super(props);
		
		setText(props);
		
		props.dispatch({type: 'RESET_SHOW_USERS'});
		props.dispatch({type: 'RESET_SHOW_USERS'});

	}

	//	When url changes for example /tag/latina/ to /tag/ebony/
	componentDidUpdate(prevProps, prevState) {
		
		//	 We don't want to duplicate load on first time component runs ( it will also trigger the componentDidUpdate )
		if( prevProps.meta_title !== "" ) {
			
			setUsers(prevProps);

			setText(prevProps);

		}
		
	}

	render() {
		return (
		<div>
			<Header />
			<div className="container">
				<div className="wrap_sidemenu_also">
					<SideMenuDesktop />
					<UserContainer showOrderBox={true} />
				</div>
				<Helmet>
					<title>{this.props.meta_title}</title>
					<meta name="description" content={this.props.meta_description} />
				</Helmet>
				<TextContent />
				{ window.innerWidth > 768 ? <Footer /> : null }
			</div>
		</div>
		);
	}
}


//	Export with redux connections.
export default connect(mapStateToProps)(Tag);