import React, { Component } from 'react';
import UserContainer from 'src/components/parts/UserContainer';
import {Helmet} from 'react-helmet';
import { connect } from "react-redux";
import { getChatrooom, setText, setUsers } from "src/cms.js";
import Img from 'react-image';
import icon_dom from 'public/static/img/icon_dom.png';
import x_icon from 'public/static/img/x_icon.png';
import Header from 'src/components/parts/Header';

//	This maps the data store to local variables.
const mapStateToProps = state  => {
	return {
	  meta_title: state.meta_title,
	  meta_description: state.meta_description,
	  content: state.content,
	  currentUser:state.currentUser,
	  top_text: state.top_text
	};
};

//	Main Home Class
class Chatroom extends Component {

	constructor(props) {

		super(props);

		var width = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;
		var height = window.innerHeight|| document.documentElement.clientHeight|| document.getElementsByTagName('body')[0].clientHeight;
		
		if( width > 1500 ) width = 1500;

		if( height > 620 )	height = width * 0.5625;
		else				height = height - 70;

		this.state = {width: width, height: height}

	}

	//	On Load
	componentDidMount() {

		getChatrooom( this.props );

		setText(this.props);

		
	}


	componentDidUpdate(prevProps, prevState) {

		//	Make sure there was a change in pages
		if( this.props.location.pathname !== prevProps.location.pathname) {

			this.props.dispatch({type: 'GET_ALL_USERS_RESET'});	// after getting initial users will allow for when scroll happens will get all users again

			this.props.dispatch({type: 'RESET_SHOW_USERS'});
			
			getChatrooom(prevProps)

			setUsers(prevProps)

			setText(prevProps)

		} else {

			//window.scrollTo({ top:0, behavior: 'smooth' });
			window.scrollTo({ top:0 });

		}
		
	}

	renderProfileImage = () => {

		// Check if it is empty.
		if( typeof this.props.currentUser.picture_url === 'undefined' ) {
			return '';
		}
		
		// Image is there return.
		return(
			<Img src={document.location.protocol+'//'+document.domain+this.props.currentUser.picture_url} className="chatroom-profile-picture" />
		);
		
	}


	render() {
	//console.log( document.location.protocol+'//'+document.domain+this.props.currentUser.iframe );

	  return (
		<div>
			<Header />
			<div className="container">
				<div id="liveroom_container">
					<div className="chatroom">
						<a href="/" className="close_chat_icon" title="Exit Chatroom"><Img src={x_icon} alt="Close Chat Icon" /></a>
						<iframe src={this.props.currentUser.iframe} height={this.state.height} width={this.state.width} title={this.props.currentUser.username} />
					</div>
					<div className="chatroom-contentwrap">
						<div className="chatroom-current-model"><Img src={icon_dom} alt="Dominatrix Icon" /> {this.props.currentUser.name}</div>
						<div className="chatroom-topnav">
							<div className="chatroom-topnav-nowlivebox">NOW LIVE</div>
							<div className="chatroom-topnav-btns">
								<a className="chatroom-topnav-btn free_chat" href={this.props.currentUser.live_room_url} title="Free Chat" alt="Free Chat Button">FREE CHAT</a>
								<a className="chatroom-topnav-btn go_private" href={this.props.currentUser.live_room_url} title="Go Private" alt="Go Private Button">GO PRIVATE</a>
								<a className="chatroom-topnav-btn join" href={this.props.currentUser.live_room_url} title="Join Free" alt="Join Button">JOIN</a>
							</div>
						</div>
						<div className="chatroom-info">
							<div><span>Age:</span><p>{this.props.currentUser.age === 0 ? '?' : this.props.currentUser.age} years old</p></div>
							<div><span>Ethnicity:</span><p>{this.props.currentUser.ethnicity === 'none' ? 'Ask' : this.props.currentUser.ethnicity}</p></div>
							<div><span>Language:</span><p>{this.props.currentUser.languages}</p></div>
							<div><span>Body Type:</span><p>{this.props.currentUser.body_type === 'none' ? 'Average' : this.props.currentUser.body_type}</p></div>
							<div><span>Hair Color:</span><p>{this.props.currentUser.hair_color === 'none' ? 'Ask' : this.props.currentUser.hair_color}</p></div>
							<div><span>Hair Length:</span><p>{this.props.currentUser.hair_length === 'none' ? 'Ask' : this.props.currentUser.hair_length}</p></div>
							<div><span>Eye Color:</span><p>{this.props.currentUser.eye_color === 'none' ? 'Ask' : this.props.currentUser.eye_color}</p></div>
							<div><span>Live Chatters:</span><p>{this.props.currentUser.chatters == 0 ? Math.floor(Math.random() * 100) : this.props.currentUser.chatters}</p></div>
							<div><span>Country Code:</span><p>{this.props.currentUser.country_code === '' ? 'US' : this.props.currentUser.country_code}</p></div>
							<div><span>What I Like:</span></div>
							<div><p>{this.props.currentUser.turn_ons == '' ? 'Private shows' : this.props.currentUser.turn_ons}</p></div>
							<div><span>Turn Offs:</span></div>
							<div><p>{this.props.currentUser.turn_offs == '' ? 'Rude people' : this.props.currentUser.turn_offs}</p></div>
							<div><span>About Me:</span></div>
							<div><p>{this.props.currentUser.bio == '' ? 'I love to perform nasty shows live for you. Join me!' : this.props.currentUser.bio}</p></div>
							<div><span>Pictures:</span></div>
							<a className="recent_shot" href={this.props.currentUser.live_room_url}>{this.renderProfileImage()}</a>
						</div>
						<h4>More Shows Like <span className="highlight">{this.props.currentUser.name}</span></h4>
						<Helmet>
							<title>{this.props.meta_title}</title>
							<meta name="description" content={this.props.meta_description} />
						</Helmet>
					</div>
				</div>
				<div className="center_chatmodels"><UserContainer /></div>
			</div>
		</div>
	  );
	}
}

//	Export with redux connections.
export default connect(mapStateToProps)(Chatroom);