import React from 'react';
import { NavLink } from 'react-router-dom';
import UserSort from 'src/components/parts/UserSort';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins, faThumbsUp, faEye, faStar } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";

const mapStateToProps = state  => {
	return {
	};
};
class SideMenuDesktop extends React.Component {

	constructor(props) {

		super(props);

	}

	loadRegister = (event) => {
		this.props.dispatch({type: 'REGISTER'});
	};

	render() {

		return (
			<div id="sidemenu__desktop">
				<div className="sidemenu">
					<div className="sidemenu_top">
						<div className="credits_box"><button className="promotions" title="Join and Earn Free Credits" onClick={this.loadRegister}><FontAwesomeIcon icon={faCoins} />FREE CREDITS</button></div>
						<div className="sidemenu_top_options">
							<NavLink to="/category/recommended/" activeClassName="active_sidepage"><FontAwesomeIcon icon={faThumbsUp} /> Top Picks</NavLink>
							<NavLink to="/category/hd/" activeClassName="active_sidepage"><FontAwesomeIcon icon={faEye} /> HD Dungeons</NavLink>
							<NavLink to="/category/new/" activeClassName="active_sidepage"><FontAwesomeIcon icon={faStar} /> New Shows</NavLink>
						</div>
					</div>
					<div className="sidemenu_categories">
						<ul id="side_list">
							<li className="side_list_title">AGE</li>
							<li><NavLink to="/category/teen/" activeClassName="active_sidepage">Teen Mistress</NavLink></li>
							<li><NavLink to="/category/milf/" activeClassName="active_sidepage">MILF Dominatrix</NavLink></li>
							<li className="side_list_title">BODY TYPE</li>
							<li><NavLink to="/category/petite/" activeClassName="active_sidepage">Petite Domme</NavLink></li>
							<li><NavLink to="/category/muscle/" activeClassName="active_sidepage">Muscle Mistress</NavLink></li>
							<li><NavLink to="/category/bbw/" activeClassName="active_sidepage">BBW Domina</NavLink></li>
							<li><NavLink to="/category/busty/" activeClassName="active_sidepage">Busty Dominatrix</NavLink></li>
						</ul>
						<UserSort />
					</div>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps)(SideMenuDesktop);