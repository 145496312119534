import React from 'react';
import {Helmet} from 'react-helmet';
import SideMenuDesktop from 'src/components/parts/SideMenuDesktop';
import Header from 'src/components/parts/Header';
import Footer from 'src/components/parts/Footer';

const Error = () => {
    return (
		<div>
			<Header />
			<div className="container">
				<div className="wrap_sidemenu_also">
					<SideMenuDesktop />
					<div className="not_found">
						<h1>404 Error - Page Not Found</h1>
						<p>This page no longer exist, we have been notified and will fix the issues.</p> 
						<p><a href="/">Click here to return to the home page</a></p>
						<Helmet>
							<title>Page Not Found</title>
							<meta name="robots" content="noindex" />
							<meta name="googlebot" content="noindex" />
						</Helmet>
					</div>
				</div>
				{ window.innerWidth > 768 ? <Footer /> : null }
			</div>
		</div>
    );
}

export default Error;
 