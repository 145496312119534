import React, { Component } from 'react';
import { connect } from "react-redux";
import Img from 'react-image';
import logo from 'public/static/img/logo.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileAlt, faUserCircle, faDollarSign, faEnvelope } from "@fortawesome/free-solid-svg-icons";


//	This maps the data store to local variables.
const mapStateToProps = state  => {
	return {
	  footer_text: state.footer_text
	};
};

//	Main Home Class
class Footer extends Component {

	constructor(props) {

		super(props);
		
	}
	
	
	loadRegister = (event) => {
		this.props.dispatch({type: 'REGISTER'});
	};

	render() {
	  return (
		<footer>
			<div className="footer_centered">
				<div className="footer_left">
					<div className="footer_left_top">
						<a href="/"><Img src={logo} className="footer_logo" alt="Femdom Cams 24/7" title="Home Page" /></a>
					</div>
					<div className="footer_left_bottom">
						<h6>No other site on the Internet offers an interactive femdom experience quite like FemdomCams247.com, we know that you want to please your Mistress, with thousands of beautiful cam queens ready to punish you on their webcam, so get ready to obey. Never again will you have to find a dominatrix that’s going to control you, because dozens are ready and waiting for slaves to join them right here on Femdom Cams 24/7. We hope you find the abusive female you truly deserve from one of our fine, upper-class dominatrix cam streamers.</h6>
					</div>
				</div>
				<div className="footer_right">
					<div>
						<h3>Work With Us <FontAwesomeIcon icon={faDollarSign} /></h3>
						<a href="/do_model_register">Be A Model</a>
						<a href="/do_webmaster_register">Webmasters</a>
					</div>
					<div>
						<h3>Discover More <FontAwesomeIcon icon={faUserCircle} /></h3>
						<a href="https://www.femdomonline.com">Femdom Online</a>
						<a href="https://badgirlscam.com">Live Fetish Girls</a>
					</div>
					<div>
						<h3>Contact <FontAwesomeIcon icon={faEnvelope} /></h3>
						<a onClick={this.loadRegister}>Get In Touch</a>
					</div>
				</div>
			</div>
		</footer>
	  );
	}
}

//	Export with redux connections.
export default connect(mapStateToProps)(Footer);